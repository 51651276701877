/*---------------------------------------------------------

drawerCard

---------------------------------------------------------*/
jQuery("#js-drawerCard")
  .children(".swiper-slide")
  .children(".card")
  .children(".card__body")
  .children(".card__foot")
  .children(".card__icon")
  .on("click", function () {
    var $cardBody = jQuery(this).closest(".card__body");
    var $cardText = $cardBody.children(".card__text");

    jQuery(this).toggleClass("is-open");
    $cardText.stop().slideToggle(200);
  });