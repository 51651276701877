/*---------------------------------------------------------

scrollEventHandler

---------------------------------------------------------*/
const intersectionObserver = new IntersectionObserver(function (entries) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      entry.target.classList.add("is-inView");
    } else {
      entry.target.classList.remove("is-inView");
    }
  });
});

// スクロールイベントを監視して要素が表示領域に入ったかを確認
window.addEventListener("scroll", function () {
  var elements = document.querySelectorAll(".js-inView");

  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    var rect = element.getBoundingClientRect();

    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
      // 要素が表示領域に入った場合、新しいクラスを追加
      element.classList.add("is-inView");
    } else {
      // 要素が表示領域から外れた場合、新しいクラスを削除
      // element.classList.remove("is-inView");
    }
  }
});

// ページ読み込み時にもチェック（初期表示時にも反映するため）
window.addEventListener("load", function () {
  var elements = document.querySelectorAll(".js-inView");

  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    var rect = element.getBoundingClientRect();

    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
      // 要素が表示領域に入っている場合、新しいクラスを追加
      element.classList.add("is-inView");
    }
  }
});
