/*---------------------------------------------------------

smoothScroll

---------------------------------------------------------*/
jQuery('a[href^="#"]').on("click", function () {
  const header = jQuery("#js-header");
  const headerHeight = header.innerHeight();
  const speed = 1000;
  const id = jQuery(this).attr("href");
  const target = jQuery("#" == id ? "html" : id);
  const position = jQuery(target).offset().top - headerHeight;

  if ("fixed" !== header.css("position")) {
    position = jQuery(target).offset().top;
  }
  if (0 > position) {
    position = 0;
  }
  jQuery("html, body").animate(
    {
      scrollTop: position,
    },
    speed,
    "easeInOutQuad" //jQueryプラグイン必須の指定
  );
});