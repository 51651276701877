/*---------------------------------------------------------

drawerFAQ

---------------------------------------------------------*/
jQuery("#js-drawerFAQ")
  .children(".p-faq-box")
  .on("click", function () {
    jQuery(this).toggleClass("is-open");
    jQuery(this).children(".p-faq-box__body").stop().slideToggle(200);
  });