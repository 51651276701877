/*---------------------------------------------------------

drawerNav

---------------------------------------------------------*/
const drawerIcon = document.querySelector("#js-drawerIcon");
const drawerMenu = document.querySelector("#js-drawerMenu");
const drawerLinks = document.querySelectorAll("#js-drawerMenu a[href^='#']");

if (drawerIcon) {
  drawerIcon.addEventListener("click", function (e) {
    e.preventDefault();
    drawerIcon.classList.toggle("is-open");
    drawerMenu.classList.toggle("is-open");
    document.body.classList.toggle("is-fixed");
  });

  drawerLinks.forEach((link) => {
    link.addEventListener("click", function (e) {
      drawerIcon.classList.remove("is-open");
      drawerMenu.classList.remove("is-open");
      document.body.classList.remove("is-fixed");
    });
  });
}
