/*---------------------------------------------------------

viewportFix
- 画面幅が指定した値(375px)未満の場合はViewportを固定する

---------------------------------------------------------*/
(function () {
  const fixedPoint = 375;
  const viewport = document.querySelector('meta[name="viewport"]');

  function switchViewport() {
    const value = window.outerWidth > fixedPoint ? "width=device-width,initial-scale=1" : "width=375";
    if (viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value);
    }
  }
  addEventListener("resize", switchViewport, false);
  switchViewport();
})();