/*---------------------------------------------------------

swiper

---------------------------------------------------------*/
const roomSlideLength = document.querySelectorAll(".p-room .swiper-slide").length;
const useSlideLength = document.querySelectorAll(".p-use .swiper-slide").length;
const voiceSlideLength = document.querySelectorAll(".p-voice .swiper-slide").length;

const initSwiper = () => {
  const roomSwiper = new Swiper(".js-swiper-room", {
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 24,
    loop: true,
    loopAdditionalSlides: 1,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
      },
    },
  });

  const useSwiper = new Swiper(".js-swiper-use", {
    slidesPerView: 1,
    spaceBetween: 16,
    loop: true,
    loopedSlides: useSlideLength,
    speed: 500,
    freeMode: {
      enabled: true,
      momentum: false,
    },
    grabCursor: true,
    navigation: {
      nextEl: ".p-use .swiper-button-next",
      prevEl: ".p-use .swiper-button-prev",
    },
    pagination: {
      el: ".p-use .swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 16,
        speed: 500,
      },
    },
    on: {
      touchEnd: (swiper) => {
        swiper.slideTo(swiper.activeIndex + 1);
      },
    },
  });

  const voiceSwiper = new Swiper(".js-swiper-voice", {
    slidesPerView: 1,
    spaceBetween: 16,
    loop: true,
    loopedSlides: voiceSlideLength,
    speed: 500,
    freeMode: {
      enabled: true,
      momentum: false,
    },
    grabCursor: true,
    navigation: {
      nextEl: ".p-voice .swiper-button-next",
      prevEl: ".p-voice .swiper-button-prev",
    },
    pagination: {
      el: ".p-voice .swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
        speed: 500,
      },
    },
  });
};

window.addEventListener("load", function () {
  initSwiper();
});
