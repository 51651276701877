/*---------------------------------------------------------

Scroll Hint

---------------------------------------------------------*/
window.addEventListener("DOMContentLoaded", function () {
  new ScrollHint(".js-scrollable", {
    scrollHintIconAppendClass: "scroll-hint-icon-white",
    suggestiveShadow: true,
    i18n: {
      scrollable: "スクロールできます",
    },
  });
});
