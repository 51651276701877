/*---------------------------------------------------------

waveCanvas

---------------------------------------------------------*/
var canvasList, // キャンバスの配列
  info = {}, // 全キャンバス共通の描画情報
  colorList, // 各キャンバスの色情報
  heightList, // 各キャンバスの波の高さ
  unitList, // 各キャンバスの単位高さ
  frequencyList; // 各キャンバスの波の間隔

/**
 * Init function.
 *
 * Initialize variables and begin the animation.
 */
function init() {
  info.seconds = 0;
  info.t = 0;
  canvasList = [];
  colorList = [];
  heightList = [];
  unitList = [];
  frequencyList = [];

  // デバイスの幅に基づいて高さと単位高さを設定
  var isMobile = window.innerWidth <= 768;

  // canvas1個目
  canvasList.push(document.getElementById("waveCanvas1"));
  colorList.push(["#C6ECEC"]);
  // スマホ用の波の設定
  if (isMobile) {
    heightList.push(16); // 波の高さ指定
    unitList.push(16); // 波の振幅
    frequencyList.push(0.7); // 波の間隔
  // PC用の波の設定
  } else {
    heightList.push(50);
    unitList.push(50);
    frequencyList.push(0.5);
  }

  // canvas2個目
  canvasList.push(document.getElementById("waveCanvas2"));
  colorList.push(["#f4fbfd"]);
  if (isMobile) {
    heightList.push(16);
    unitList.push(16);
    frequencyList.push(0.7);
  } else {
    heightList.push(33);
    unitList.push(33);
    frequencyList.push(0.5);
  }

  // canvas3個目
  canvasList.push(document.getElementById("waveCanvas3"));
  colorList.push(["#fff"]);
  if (isMobile) {
    heightList.push(16);
    unitList.push(16);
    frequencyList.push(0.7);
  } else {
    heightList.push(33);
    unitList.push(33);
    frequencyList.push(0.5);
  }

  // canvas4個目
  canvasList.push(document.getElementById("waveCanvas4"));
  colorList.push(["#f4fbfd"]);
  if (isMobile) {
    heightList.push(5);
    unitList.push(5);
    frequencyList.push(0.1);
  } else {
    heightList.push(20);
    unitList.push(20);
    frequencyList.push(0.2);
  }

  // canvas5個目
  canvasList.push(document.getElementById("waveCanvas5"));
  colorList.push(["#fff"]);
  if (isMobile) {
    heightList.push(5);
    unitList.push(5);
    frequencyList.push(0.1);
  } else {
    heightList.push(20);
    unitList.push(20);
    frequencyList.push(0.2);
  }

  // canvas6個目
  canvasList.push(document.getElementById("waveCanvas6"));
  colorList.push(["#f4fbfd"]);
  if (isMobile) {
    heightList.push(5);
    unitList.push(5);
    frequencyList.push(0.1);
  } else {
    heightList.push(20);
    unitList.push(20);
    frequencyList.push(0.2);
  }

  // canvas7個目
  canvasList.push(document.getElementById("waveCanvas7"));
  colorList.push(["#fff"]);
  if (isMobile) {
    heightList.push(5);
    unitList.push(5);
    frequencyList.push(0.1);
  } else {
    heightList.push(20);
    unitList.push(20);
    frequencyList.push(0.2);
  }

  // canvas8個目
  canvasList.push(document.getElementById("waveCanvas8"));
  colorList.push(["#f4fbfd"]);
  if (isMobile) {
    heightList.push(16);
    unitList.push(16);
    frequencyList.push(0.7);
  } else {
    heightList.push(33);
    unitList.push(33);
    frequencyList.push(0.5);
  }

  // canvas9個目
  canvasList.push(document.getElementById("waveCanvas9"));
  colorList.push(["#fff"]);
  if (isMobile) {
    heightList.push(16);
    unitList.push(16);
    frequencyList.push(0.7);
  } else {
    heightList.push(33);
    unitList.push(33);
    frequencyList.push(0.5);
  }

  // canvas10個目
  canvasList.push(document.getElementById("waveCanvas10"));
  colorList.push(["#f4fbfd"]);
  if (isMobile) {
    heightList.push(40);
    unitList.push(40);
    frequencyList.push(0.9);
  } else {
    heightList.push(101);
    unitList.push(101);
    frequencyList.push(0.9);
  }

  // 各キャンバスの初期化
  for (var canvasIndex in canvasList) {
    var canvas = canvasList[canvasIndex];
    canvas.width = document.documentElement.clientWidth; // Canvasのwidthをウィンドウの幅に合わせる
    canvas.height = heightList[canvasIndex]; // 波の高さを個別に設定
    canvas.contextCache = canvas.getContext("2d");
  }

  // 共通の更新処理呼び出し
  update();
}

function update() {
  for (var canvasIndex in canvasList) {
    var canvas = canvasList[canvasIndex];
    // 各キャンバスの描画
    draw(canvas, colorList[canvasIndex], heightList[canvasIndex], unitList[canvasIndex], frequencyList[canvasIndex]);
  }

  // 共通の描画情報の更新(ウネリの速度)
  info.seconds = info.seconds + 0.02;
  info.t = info.seconds * Math.PI;

  // 自身の再起呼び出し
  setTimeout(update, 35);
}

/**
 * Draw animation function.
 *
 * This function draws one frame of the animation, waits 20ms, and then calls
 * itself again.
 */
function draw(canvas, color, height, unit, frequency) {
  // 対象のcanvasのコンテキストを取得
  var context = canvas.contextCache;
  // キャンバスの描画をクリア
  context.clearRect(0, 0, canvas.width, canvas.height);

  // 波を描画 drawWave(canvas, color[数字（波の数を0から数えて指定）], 透過, 波の幅のzoom, 波の開始位置の遅れ, 波の高さ, 単位高さ, 波の間隔)
  drawWave(canvas, color[0], 1, 3, 0, height, unit, frequency);
}

/**
 * 波を描画
 * drawWave(色, 不透明度, 波の幅のzoom, 波の開始位置の遅れ, 波の高さ, 単位高さ, 波の間隔)
 */
function drawWave(canvas, color, alpha, zoom, delay, height, unit, frequency) {
  var context = canvas.contextCache;
  context.fillStyle = color; // 塗りの色
  context.globalAlpha = alpha;
  context.beginPath(); // パスの開始
  drawSine(canvas, info.t / 0.5, zoom, delay, height, unit, frequency);
  context.lineTo(canvas.width + 10, canvas.height); // パスをCanvasの右下へ
  context.lineTo(0, canvas.height); // パスをCanvasの左下へ
  context.closePath(); // パスを閉じる
  context.fill(); // 波を塗りつぶす
}

/**
 * Function to draw sine
 *
 * The sine curve is drawn in 10px segments starting at the origin.
 * drawSine(時間, 波の幅のzoom, 波の開始位置の遅れ, 波の高さ, 単位高さ, 波の間隔)
 */
function drawSine(canvas, t, zoom, delay, height, unit, frequency) {
  var xAxis = Math.floor(height / 2);
  var yAxis = 0;
  var context = canvas.contextCache;
  // Set the initial x and y, starting at 0,0 and translating to the origin on
  // the canvas.
  var x = t; // 時間を横の位置とする
  var y = Math.sin(x * frequency) / zoom;
  context.moveTo(yAxis, unit * y + xAxis); // スタート位置にパスを置く

  // Loop to draw segments (横幅の分、波を描画)
  for (i = yAxis; i <= canvas.width + 10; i += 10) {
    x = t + (-yAxis + i) / unit / zoom;
    y = Math.sin((x - delay) * frequency) / 3;
    context.lineTo(i, unit * y + xAxis);
  }
}

init();
